import styled from '@emotion/styled/macro';
import { space } from 'styled-system';
import { maxWidth, minWidth } from '@feast-it/pesto';

export const GridView = styled.div`
  ${space}
`;

export const Item = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space[4]};

  ${({ theme }) => minWidth(theme.breakpoints.s)} {
    grid-gap: ${({ theme }) => theme.space[7]};
  }

  ${({ theme }) => minWidth(theme.breakpoints.m)} {
    display: block;
  }
`;

export const ItemLink = styled.a`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ItemImage = styled.div`
  ${space}
  border-radius: ${({ theme }) => theme.radii[1]};
  overflow: hidden;
  grid-row: 1;
  grid-column: ${({ reversed }) => (reversed ? '2' : '1')};

  & > * {
    transition: transform ${({ theme }) => theme.transitions.medium};
  }

  ${Item}:hover & > * {
    transform: scale(1.1);
  }

  ${({ theme }) => maxWidth(theme.breakpoints.m)} {
    ${({ reversed }) =>
      reversed
        ? `border-top-right-radius: 0; border-bottom-right-radius: 0;`
        : `border-top-left-radius: 0; border-bottom-left-radius: 0;`}

    * {
      height: 100%;
    }
  }
`;

export const ItemCta = styled.div`
  position: relative;
  z-index: 2;

  ${({ theme }) => minWidth(theme.breakpoints.m)} {
    opacity: 0;
    transform: translateY(10px);
    transition-property: opacity, transform;
    transition-duration: ${({ theme }) => theme.transitions.medium};

    ${Item}:hover & {
      opacity: 1;
      transform: none;
    }
  }
`;

export const ItemContent = styled.div`
  grid-row: 1;
  grid-column: ${({ reversed }) => (reversed ? '1' : '2')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
