import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Heading,
  Text,
  Image,
  Button,
  Grid,
  base,
  Flex,
} from '@feast-it/pesto';
import { useInView } from 'react-intersection-observer';

import { useBreakpoint } from '../../hooks/media';

import * as Styled from './styles';

const Item = ({ heading, text, ctaText, ctaLink, image, reversed }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <Styled.Item ref={ref}>
      {image && (
        <Styled.ItemImage
          reversed={reversed}
          mr={reversed ? { _: -4, s: -8, m: 0 } : 0}
          ml={reversed ? 0 : { _: -4, s: -8, m: 0 }}
        >
          <Image src={image} width={560} height={720} inView={inView} imgix />
        </Styled.ItemImage>
      )}

      <Styled.ItemContent reversed={reversed}>
        {heading && (
          <>
            <Heading
              variant={{ _: 'headingMediumMobile', m: 'headingMedium' }}
              color="white"
              mt={{ m: 4 }}
              as="h3"
            >
              {heading}
            </Heading>

            <Box
              width={{ _: '100%', s: 85 }}
              height={3}
              bg="tertiary"
              borderRadius={2}
              mt={4}
            />
          </>
        )}

        {text && (
          <Text
            fontFamily="heading"
            variant={{ _: 'small', m: 'body' }}
            color="white"
            mt={{ _: 4, m: 6 }}
          >
            {text}
          </Text>
        )}

        {ctaLink && ctaText && (
          <Styled.ItemCta>
            <Button
              size={{ _: 'small', m: 'medium' }}
              variant="light"
              href={ctaLink}
              mt={{ _: 4, m: 6 }}
            >
              {ctaText}
            </Button>
          </Styled.ItemCta>
        )}

        {ctaLink && <Styled.ItemLink href={ctaLink} />}
      </Styled.ItemContent>
    </Styled.Item>
  );
};

Item.defaultProps = {
  shape: 1,
};

Item.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  reversed: PropTypes.bool,
  setImageHeight: PropTypes.func,
};

const GridView = ({ items, ...props }) => {
  const isDesktop = useBreakpoint(base.breakpoints.m);

  return (
    <Styled.GridView {...props}>
      {isDesktop ? (
        <Flex
          gap="40px"
          flexWrap="wrap"
          justifyContent="center"
          margin="-40px 0 0 -40px"
        >
          {items.map((item, i) => (
            <Box key={`desktop-item-${i}`} width="250px" margin="40px 0 0 40px">
              <Item {...item} />
            </Box>
          ))}
        </Flex>
      ) : (
        <Grid gridGap={9}>
          {items.map((item, i) => (
            <Item key={`mobile-item-${i}`} reversed={i % 2} {...item} />
          ))}
        </Grid>
      )}
    </Styled.GridView>
  );
};

GridView.propTypes = {
  items: PropTypes.array.isRequired,
};

export default GridView;
