import React from 'react';
import { Box, Heading, Container, Text, rem } from '@feast-it/pesto';

import GridView from '../../components/Gridview';

const GridViewContainer = rawData => {
  const data = {
    heading: rawData?.primary?.heading,
    subHeading: rawData?.primary?.sub_header,
    items: rawData?.items?.map(item => ({
      heading: item?.item_heading,
      text: item?.item_text,
      image: item?.item_image?.url,
      ctaText: item?.item_cta_text,
      ctaLink: item?.cta_link?.url,
    })),
  };

  return (
    <Box as="section" overflow="hidden" mb={{ m: -4 }}>
      <Box
        bg="primaryDark"
        py={{ _: 9, m: 10 }}
        px={{ _: 'gutter._', s: 'gutter.s', m: 'gutter.m' }}
        minHeight={rem(600)}
      >
        <Container>
          <Heading
            as="h2"
            variant={{ _: 'headingExtraLargeMobile', m: 'headingExtraLarge' }}
            color="white"
            textAlign="center"
            mb={data.subHeading ? 4 : 9}
          >
            {data.heading}
          </Heading>

          {data.subHeading && (
            <Text
              fontFamily="heading"
              color="white"
              textAlign="center"
              fontSize="large"
              mb={9}
            >
              {data.subHeading}
            </Text>
          )}

          <GridView items={data.items} />
        </Container>
      </Box>
    </Box>
  );
};

export default GridViewContainer;
